import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    {
      attrs: {
        solid: "",
        color: "white",
        fluid: "",
        tag: "section",
        id: "companyPage",
      },
    },
    [
      _c("CompanyAppBar", {
        attrs: {
          TabOptions: _vm.tab_options,
          admin_type: _vm.admin_type,
          company_name: _vm.company.Name,
          UserType: _vm.userType,
        },
      }),
      _c("div", { staticStyle: { "margin-top": "13vh" } }, [
        _vm.selected_index === 0
          ? _c(
              "div",
              [
                _vm.companyLoading
                  ? _c(
                      VRow,
                      { attrs: { justify: "center" } },
                      [
                        _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            size: "15",
                            color: "primary",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("CompanyDetails", {
                      attrs: {
                        UserTotalInvested: _vm.gs.format_to_currency(
                          _vm.get_total_invested(_vm.user_investment)
                        ),
                        InvestorsLoading: _vm.investorsLoading,
                        FilesLoading: _vm.filesLoading,
                        StockHistoryLoading: _vm.stockHistoryLoading,
                        company: _vm.company,
                        UserType: _vm.userType,
                        admin_type: _vm.admin_type,
                        can_edit: _vm.can_edit,
                        investors: _vm.company.Investors,
                        files: _vm.company.Files,
                      },
                    }),
              ],
              1
            )
          : _vm._e(),
        _vm.selected_index === 1
          ? _c(
              "div",
              [
                _vm.companyLoading ||
                _vm.investorsLoading ||
                _vm.transactionsLoading
                  ? _c(
                      VRow,
                      { attrs: { justify: "center" } },
                      [
                        _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            size: "15",
                            color: "primary",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm.company.Investors
                  ? _c("DashboardInvestorsTable", {
                      attrs: {
                        company: _vm.company,
                        Investors: _vm.company.Investors,
                        is_admin: _vm.userType == _vm.userTypeEnum.Admin,
                        can_edit: _vm.can_edit,
                      },
                      on: {
                        update: function (value) {
                          _vm.company.Investors = _vm.investors
                        },
                        reload: function ($event) {
                          return _vm.investments_changed()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.selected_index === 2 && _vm.userType === _vm.userTypeEnum.Investor
          ? _c(
              "div",
              [
                _vm.companyLoading
                  ? _c(
                      VRow,
                      { attrs: { justify: "center" } },
                      [
                        _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            size: "15",
                            color: "primary",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm.userType < 2
                  ? _c("DashboardReportTable", {
                      attrs: { companyId: _vm.company_id, userId: _vm.userId },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.selected_index === 3
          ? _c(
              "div",
              [
                _vm.filesLoading
                  ? _c(
                      VRow,
                      { attrs: { justify: "center" } },
                      [
                        _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            size: "15",
                            color: "primary",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("DashboardFilesTable", {
                      attrs: {
                        company_id: _vm.company_id,
                        files_prop: _vm.company.Files,
                        is_admin: _vm.userType == _vm.userTypeEnum.Admin,
                      },
                    }),
              ],
              1
            )
          : _vm._e(),
        _vm.selected_index === 4
          ? _c(
              "div",
              [
                _vm.transactionsLoading
                  ? _c(
                      VRow,
                      { attrs: { justify: "center" } },
                      [
                        _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            size: "15",
                            color: "primary",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("DashboardTransactionsTable", {
                      attrs: {
                        company: _vm.company,
                        transactions: _vm.transactions,
                        investors: _vm.company.Investors,
                        is_admin: _vm.userType == _vm.userTypeEnum.Admin,
                        can_edit: _vm.can_edit,
                      },
                      on: { loadingTransaction: _vm.reload_transactions },
                    }),
              ],
              1
            )
          : _vm._e(),
        _vm.selected_index === 5
          ? _c(
              "div",
              [
                _vm.stockHistoryLoading
                  ? _c(
                      VRow,
                      { attrs: { justify: "center" } },
                      [
                        _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            size: "15",
                            color: "primary",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("DashboardStocksTable", {
                      attrs: {
                        company: _vm.company,
                        is_admin: _vm.userType == _vm.userTypeEnum.Admin,
                        can_edit: _vm.can_edit,
                      },
                      on: { update: _vm.updateStocks },
                    }),
              ],
              1
            )
          : _vm._e(),
        _vm.selected_index === 7
          ? _c(
              "div",
              [
                _vm.kpisLoading
                  ? _c(
                      VRow,
                      { attrs: { justify: "center" } },
                      [
                        _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            size: "15",
                            color: "primary",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("DashboardKpiTable", {
                      attrs: {
                        company: _vm.company,
                        kpis: _vm.company.CompanyKpis,
                        is_admin:
                          _vm.userType == _vm.userTypeEnum.Admin ||
                          _vm.userType == _vm.userTypeEnum.ContentAnalyst,
                        can_edit: _vm.can_edit,
                      },
                      on: { reload: _vm.reload_company_kpis },
                    }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }