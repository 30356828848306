import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.selectedKpi
    ? _c(
        VContainer,
        { attrs: { id: "kpi-table", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: {
                color: "#7733FF",
                icon: "mdi-bullseye-arrow",
                inline: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "after-heading",
                    fn: function () {
                      return [
                        _c("h1", { staticClass: "h4" }, [
                          _c("b", [_vm._v(_vm._s(_vm.$tc("kpi", 2)))]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1904929678
              ),
            },
            [
              _c(
                VBtn,
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.is_admin,
                      expression: "is_admin",
                    },
                  ],
                  attrs: {
                    absolute: "",
                    fab: "",
                    top: "",
                    right: "",
                    color: "primary",
                    "data-test": "Company:Kpi:Add:Button",
                    rounded: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.open_dialog()
                    },
                  },
                },
                [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
                1
              ),
              _c(VSimpleTable, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("name_pt"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("name_en"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("description"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("current_value"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("date"))),
                    ]),
                    _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.is_admin,
                            expression: "is_admin",
                          },
                        ],
                        staticClass: "text-center",
                      },
                      [_vm._v(_vm._s(_vm.$t("options")))]
                    ),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.kpis, function (kpi, i) {
                    return _c("tr", { key: i }, [
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(kpi.Name)),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.getKpiReportEnName(kpi))),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(kpi.Description)),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              kpi.KpiValueHistory.length > 0
                                ? kpi.KpiValueHistory[
                                    kpi.KpiValueHistory.length - 1
                                  ].Value
                                : 0
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.get_date_formated(
                                kpi.KpiValueHistory.length > 0
                                  ? kpi.KpiValueHistory[
                                      kpi.KpiValueHistory.length - 1
                                    ].Date
                                  : null
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.is_admin,
                              expression: "is_admin",
                            },
                          ],
                          staticClass: "text-center",
                        },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "px-2 ml-1",
                              attrs: {
                                color: "secondary",
                                "min-width": "0",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.selectedKpi = kpi
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-eye"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "px-2 ml-1",
                              attrs: {
                                color: "secondary",
                                "min-width": "0",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.open_edit_value_dialog(kpi)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-plus"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "px-2 ml-1",
                              attrs: {
                                color: "secondary",
                                "min-width": "0",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.open_edit_dialog(kpi)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-pencil"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "px-2 ml-1",
                              attrs: {
                                color: "red",
                                "min-width": "0",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.open_delete_dialog(kpi)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-trash-can"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _vm.dialog
            ? _c(
                VDialog,
                {
                  attrs: { scrollable: "" },
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c("DashboardFormsAddKpiModal", {
                    attrs: {
                      company: _vm.company,
                      Indexes: _vm.indexes_list,
                      Kpi: _vm.editKpi,
                    },
                    on: { close: _vm.reset_dialog },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dialogEdit
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.dialogEdit,
                    callback: function ($$v) {
                      _vm.dialogEdit = $$v
                    },
                    expression: "dialogEdit",
                  },
                },
                [
                  _c("DashboardFormsEditKpiModal", {
                    attrs: { company: _vm.company, kpi: _vm.editKpiValue },
                    on: { close: _vm.reset_dialogEdit },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.delete_dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.delete_dialog,
                    callback: function ($$v) {
                      _vm.delete_dialog = $$v
                    },
                    expression: "delete_dialog",
                  },
                },
                [
                  _c("DeleteConfirmationModal", {
                    attrs: { obj: _vm.delete_obj, name: _vm.delete_obj.Name },
                    on: {
                      close: function ($event) {
                        _vm.delete_dialog = false
                      },
                      delete: _vm.delete_kpi,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c("KpiHistoryTable", {
        attrs: { Kpi: _vm.selectedKpi },
        on: { reset: _vm.reset_selected_kpi },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }