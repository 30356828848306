import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.historyTable
    ? _c(
        VContainer,
        { attrs: { id: "stocks", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: {
                color: "#7733FF",
                icon: "mdi-chart-areaspline",
                inline: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "after-heading",
                    fn: function () {
                      return [
                        _c("h1", { staticClass: "h4" }, [
                          _c("b", [_vm._v(_vm._s(_vm.$t("stocks")))]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                458549522
              ),
            },
            [
              _c(
                VBtn,
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.is_admin,
                      expression: "is_admin",
                    },
                  ],
                  staticStyle: { "margin-right": "143px" },
                  style: _vm.gs.isMobile()
                    ? "width: 42px;  height: 42px; margin-right: 123px"
                    : "",
                  attrs: {
                    absolute: "",
                    fab: "",
                    top: "",
                    loading: _vm.loadDownload,
                    right: "",
                    rounded: "",
                    color: "secondary",
                    "min-width": "0",
                  },
                  on: { click: _vm.export_stockholders },
                },
                [
                  _vm.gs.isMobile()
                    ? _c(VIcon, [_vm._v("mdi-download")])
                    : _c(VIcon, { attrs: { large: "" } }, [
                        _vm._v("mdi-download"),
                      ]),
                ],
                1
              ),
              _c(
                VBtn,
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.is_admin,
                      expression: "is_admin",
                    },
                  ],
                  staticStyle: { "margin-right": "80px" },
                  style: _vm.gs.isMobile()
                    ? "width: 42px;  height: 42px; margin-right: 70px"
                    : "",
                  attrs: {
                    absolute: "",
                    fab: "",
                    top: "",
                    right: "",
                    rounded: "",
                    color: "secondary",
                    "min-width": "0",
                  },
                  on: {
                    click: function ($event) {
                      _vm.historyTable = true
                    },
                  },
                },
                [
                  _vm.gs.isMobile()
                    ? _c(VIcon, [_vm._v("mdi-eye")])
                    : _c(VIcon, { attrs: { large: "" } }, [
                        _vm._v("mdi-eye"),
                      ]),
                ],
                1
              ),
              _c(
                VMenu,
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              VBtn,
                              _vm._g(
                                _vm._b(
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.is_admin,
                                        expression: "is_admin",
                                      },
                                    ],
                                    style: _vm.gs.isMobile()
                                      ? "width: 42px;  height: 42px"
                                      : "",
                                    attrs: {
                                      absolute: "",
                                      fab: "",
                                      top: "",
                                      right: "",
                                      color: "primary",
                                      rounded: "",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm.gs.isMobile()
                                  ? _c(VIcon, [_vm._v("mdi-dots-vertical")])
                                  : _c(VIcon, { attrs: { large: "" } }, [
                                      _vm._v("mdi-dots-vertical"),
                                    ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2853542586
                  ),
                },
                [
                  _c(
                    VList,
                    [
                      _c(
                        VListItem,
                        {
                          on: {
                            click: function ($event) {
                              return _vm.open_dialog(null)
                            },
                          },
                        },
                        [
                          _c(VListItemTitle, [
                            _vm._v(_vm._s(_vm.$t("add_stock_company"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VListItem,
                        {
                          on: {
                            click: function ($event) {
                              return _vm.open_edit_dialog()
                            },
                          },
                        },
                        [
                          _c(VListItemTitle, [
                            _vm._v(_vm._s(_vm.$t("update_stock_value"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(VSimpleTable, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("name"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("description"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$tc("stock", 1))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.latest_stocks(), function (stock, i) {
                    return _c("tr", { key: i }, [
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(stock.StockType.Name)),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(stock.StockType.Description)),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v("R$ " + _vm._s(stock.Value.toString())),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _vm.dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c("DashboardFormsAddStockModal", {
                    attrs: {
                      company: _vm.company,
                      stock: _vm.editStock,
                      ExistingStocksIds: _vm.latest_stocks().map(function (x) {
                        return x.StockTypeId
                      }),
                    },
                    on: { close: _vm.reset_dialog },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dialogEdit
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.dialogEdit,
                    callback: function ($$v) {
                      _vm.dialogEdit = $$v
                    },
                    expression: "dialogEdit",
                  },
                },
                [
                  _c("DashboardFormsEditStockModal", {
                    attrs: { company: _vm.company, stocks: _vm.editStock },
                    on: { close: _vm.reset_dialog },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c("StockHistoryTable", {
        attrs: {
          Company: _vm.company,
          CompanyStockHistory: this.company.StockValueHistory,
        },
        on: {
          reset: function ($event) {
            _vm.historyTable = false
          },
          update: _vm.update_stock_history,
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }