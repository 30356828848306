import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VAppBar,
    {
      attrs: {
        absolute: "",
        color: "white",
        "elevate-on-scroll": "",
        "scroll-target": "#scrolling-techniques-7",
      },
      scopedSlots: _vm._u([
        {
          key: "extension",
          fn: function () {
            return [
              _c(
                VTabs,
                {
                  attrs: {
                    "show-arrows": "",
                    grow: "",
                    centered: "",
                    "background-color": "transparent",
                  },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                _vm._l(_vm.TabOptions, function (item, index) {
                  return _c(
                    VTab,
                    {
                      key: index,
                      attrs: {
                        "data-test":
                          "VehicleTransactions:AppBar:Opt" + (item.index + 1),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.index_changed(item.index)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  )
                }),
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(VToolbarTitle, { staticClass: "mx-auto mb-10 pt-10" }, [
        _c("h1", { staticClass: "appBar-title" }, [
          _vm._v(_vm._s(_vm.company_name)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }