import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { class: _vm.gs.isMobile() ? "" : "dxa_modal" },
    [
      _c(VCardTitle, { staticClass: "mb-2" }, [
        _c("h4", { staticClass: "dxa_modal_title h4" }, [
          _vm._v(_vm._s(_vm.page_title())),
        ]),
      ]),
      _c(
        VCardText,
        [
          _c(
            VForm,
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.submit()
                },
              },
            },
            [
              !_vm.edit
                ? _c(
                    "div",
                    [
                      _c(
                        VRadioGroup,
                        {
                          attrs: { row: "" },
                          model: {
                            value: _vm.existing_user,
                            callback: function ($$v) {
                              _vm.existing_user = $$v
                            },
                            expression: "existing_user",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: {
                              label: _vm.$t("existing_user"),
                              value: true,
                            },
                          }),
                          _c(VRadio, {
                            attrs: {
                              label: _vm.$t("other_user"),
                              value: false,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.existing_user && !_vm.edit
                ? _c(VAutocomplete, {
                    attrs: {
                      items: _vm.users,
                      "item-text": "email",
                      "item-value": "id",
                      rules: [_vm.required],
                      label: _vm.$tc("user", 1),
                      dense: "",
                      id: "userInput",
                    },
                    on: { keyup: _vm.getUsers },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-data",
                          fn: function () {
                            return [
                              _c(
                                VListItem,
                                [
                                  _c(VListItemTitle, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.userTextFollback
                                            ? _vm.$t("write_user")
                                            : _vm.$t("not_found_user")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      530573366
                    ),
                    model: {
                      value: _vm.user_selected,
                      callback: function ($$v) {
                        _vm.user_selected = $$v
                      },
                      expression: "user_selected",
                    },
                  })
                : !_vm.existing_user
                ? _c(
                    "div",
                    [
                      _c(VTextField, {
                        staticClass: "pt-0",
                        attrs: {
                          rules: [_vm.required],
                          label: _vm.$tc("investor", 1),
                        },
                        model: {
                          value: _vm.investor.InvestorName,
                          callback: function ($$v) {
                            _vm.$set(_vm.investor, "InvestorName", $$v)
                          },
                          expression: "investor.InvestorName",
                        },
                      }),
                      !_vm.edit
                        ? _c(VTextField, {
                            staticClass: "pt-0",
                            attrs: {
                              rules: [_vm.required],
                              label: _vm.$t("email"),
                            },
                            model: {
                              value: _vm.investor.InvestorEmail,
                              callback: function ($$v) {
                                _vm.$set(_vm.investor, "InvestorEmail", $$v)
                              },
                              expression: "investor.InvestorEmail",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VMenu,
                {
                  ref: "menuDate",
                  attrs: {
                    "close-on-content-click": false,
                    "return-value": _vm.date,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "290px",
                  },
                  on: {
                    "update:returnValue": function ($event) {
                      _vm.date = $event
                    },
                    "update:return-value": function ($event) {
                      _vm.date = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            VTextField,
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "pt-0",
                                  attrs: {
                                    label: _vm.$t("date"),
                                    "prepend-icon": "mdi-calendar",
                                    rules: [_vm.required],
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.date,
                                    callback: function ($$v) {
                                      _vm.date = $$v
                                    },
                                    expression: "date",
                                  },
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.menuDate,
                    callback: function ($$v) {
                      _vm.menuDate = $$v
                    },
                    expression: "menuDate",
                  },
                },
                [
                  _c(VDatePicker, {
                    staticClass: "mx-auto",
                    on: {
                      change: function ($event) {
                        _vm.$refs.menuDate.save(_vm.date)
                        _vm.menuDate = false
                      },
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
              _c(VCombobox, {
                attrs: {
                  rules: [_vm.required],
                  items: _vm.vehicles,
                  "item-text": "InvestmentVehicle.Name",
                  "item-value": "InvestmentVehicle.Id",
                  label: _vm.$t("vehicle_title_single"),
                },
                model: {
                  value: _vm.vehicleSelected,
                  callback: function ($$v) {
                    _vm.vehicleSelected = $$v
                  },
                  expression: "vehicleSelected",
                },
              }),
              !_vm.edit
                ? _c(VSelect, {
                    attrs: {
                      rules: [_vm.required],
                      items: _vm.currencyOptions,
                      "item-text": "text",
                      "item-value": "value",
                      label: _vm.$t("currency"),
                      dense: "",
                      color: "primary",
                    },
                    on: { change: _vm.getVehicleStocks },
                    model: {
                      value: _vm.investor.Currency,
                      callback: function ($$v) {
                        _vm.$set(_vm.investor, "Currency", $$v)
                      },
                      expression: "investor.Currency",
                    },
                  })
                : _vm._e(),
              _c(VTextField, {
                staticClass: "pt-0",
                attrs: { rules: [_vm.required], label: _vm.$t("role") },
                model: {
                  value: _vm.investor.Role,
                  callback: function ($$v) {
                    _vm.$set(_vm.investor, "Role", $$v)
                  },
                  expression: "investor.Role",
                },
              }),
              !_vm.edit
                ? _c(
                    "div",
                    [
                      _c(VSelect, {
                        attrs: {
                          rules: [_vm.required],
                          items: _vm.stocks,
                          loading: _vm.stocksLoading,
                          "return-object": "",
                          label: _vm.$t("stock_type"),
                          "item-text": "stockTypeName",
                          "item-value": "stockTypeId",
                        },
                        on: { change: _vm.handleStockType },
                        model: {
                          value: _vm.stock_type,
                          callback: function ($$v) {
                            _vm.stock_type = $$v
                          },
                          expression: "stock_type",
                        },
                      }),
                      !_vm.edit
                        ? _c(VAutocomplete, {
                            attrs: {
                              items: _vm.roundOptions,
                              "item-text": "id",
                              "item-value": "id",
                              label: _vm.$t("single_round"),
                              dense: "",
                              id: "userInput",
                              loading: _vm.roundOptionsLoading,
                            },
                            model: {
                              value: _vm.investor.InvestmentRoundId,
                              callback: function ($$v) {
                                _vm.$set(_vm.investor, "InvestmentRoundId", $$v)
                              },
                              expression: "investor.InvestmentRoundId",
                            },
                          })
                        : _vm._e(),
                      !_vm.edit && !_vm.disabledInputStockValue
                        ? _c("div", [
                            _c(
                              "div",
                              [
                                _c(
                                  "span",
                                  { staticClass: "v-label theme--light" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$tc("stock", 1)) + " "
                                    ),
                                  ]
                                ),
                                _c(VTextField, {
                                  attrs: {
                                    prefix: _vm.moneyInputPrefix,
                                    disabled: _vm.disabledStockValue,
                                    rules: [_vm.required],
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.investor.StockValue,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.investor, "StockValue", $$v)
                                    },
                                    expression: "investor.StockValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(VCheckbox, {
                                  staticClass: "field",
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    dense: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "var(--dark)",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("edit_stock_value")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2595290605
                                  ),
                                  model: {
                                    value: _vm.editStockValue,
                                    callback: function ($$v) {
                                      _vm.editStockValue = $$v
                                    },
                                    expression: "editStockValue",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c(VTextField, {
                        staticClass: "pt-0",
                        attrs: {
                          type: "number",
                          rules: [_vm.required],
                          label: _vm.$t("current_position"),
                        },
                        model: {
                          value: _vm.investor.StockQuantity,
                          callback: function ($$v) {
                            _vm.$set(_vm.investor, "StockQuantity", $$v)
                          },
                          expression: "investor.StockQuantity",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VCardActions,
                { staticClass: "pl-0 dxa_modal_actions" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnSuccess",
                      attrs: {
                        color: "primary",
                        "min-width": "100",
                        loading: _vm.loading,
                        type: "submit",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("save")))]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnError",
                      attrs: { color: "red", "min-width": "100" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close", null)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.error
            ? _c(VAlert, { staticClass: "ma-2", attrs: { type: "error" } }, [
                _vm._v(_vm._s(_vm.$t(_vm.error))),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }