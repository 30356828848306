import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "investors", fluid: "", tag: "section" } },
    [
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3 mb-5",
          attrs: { color: "#7733FF", icon: "mdi-account-cash", inline: "" },
          scopedSlots: _vm._u([
            {
              key: "after-heading",
              fn: function () {
                return [
                  _c("h1", { staticClass: "h4" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$tc("investor", 2)))]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VBtn,
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.is_admin,
                  expression: "is_admin",
                },
              ],
              attrs: {
                id: "btn-add-new-investor",
                absolute: "",
                fab: "",
                top: "",
                right: "",
                color: "primary",
                rounded: "",
              },
              on: {
                click: function ($event) {
                  return _vm.open_dialog(null)
                },
              },
            },
            [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
            1
          ),
          _c(VSimpleTable, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("name"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("email"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("vehicle_title_single"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("current_position"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("invested"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("current_value"))),
                ]),
                _c(
                  "th",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.is_admin,
                        expression: "is_admin",
                      },
                    ],
                    staticClass: "text-center",
                  },
                  [_vm._v(_vm._s(_vm.$t("edit")))]
                ),
                _c(
                  "th",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.is_admin,
                        expression: "is_admin",
                      },
                    ],
                    staticClass: "text-center",
                  },
                  [_vm._v(_vm._s(_vm.$t("remove")))]
                ),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.investors, function (investor, i) {
                return _c("tr", { key: i }, [
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$parent.show_string_value(investor.InvestorName)
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(investor.InvestorEmail)),
                  ]),
                  investor.InvestmentVehicle
                    ? _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          " " + _vm._s(investor.InvestmentVehicle.Name) + " "
                        ),
                      ])
                    : _c("td", { staticClass: "text-center" }, [_vm._v("-")]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          investor.StockQuantity.toLocaleString(
                            _vm.$i18n.locale
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatToCurrency(
                            investor.Currency,
                            investor.TotalInvested
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatToCurrency(
                            investor.Currency,
                            investor.CurrentPosition
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.is_admin,
                          expression: "is_admin",
                        },
                      ],
                      staticClass: "text-center",
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1 secondary",
                          attrs: { "min-width": "0", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.open_dialog(investor)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-pencil"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.is_admin,
                          expression: "is_admin",
                        },
                      ],
                      staticClass: "text-center",
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1",
                          attrs: { color: "red", "min-width": "0", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.open_delete_dialog(investor)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-trash-can"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _vm.dialog_noStocks
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialog_noStocks,
                callback: function ($$v) {
                  _vm.dialog_noStocks = $$v
                },
                expression: "dialog_noStocks",
              },
            },
            [
              _c(
                VContainer,
                {
                  attrs: {
                    id: "add-investor-modal",
                    fluid: "",
                    tag: "section",
                  },
                },
                [
                  _c(
                    VCard,
                    { staticClass: "dxa_modal" },
                    [
                      _c(VCardTitle, { staticClass: "mb-2" }, [
                        _c(
                          "h4",
                          {
                            staticClass: "dxa_modal_title h4",
                            staticStyle: { "text-align": "center" },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("have_no_stocks")) + " ")]
                        ),
                      ]),
                      _c(
                        VCardText,
                        [
                          _c(
                            VCardActions,
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "dxa_modal_btnSuccess",
                                  attrs: {
                                    color: "primary",
                                    "min-width": "100",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialog_noStocks = false
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("ok")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.dialog
        ? _c(
            VDialog,
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c("DashboardFormsAddInvestorModal", {
                attrs: {
                  investor_prop: _vm.editInvestor,
                  company: _vm.company,
                },
                on: { close: _vm.reset_dialog },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.delete_dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.delete_dialog,
                callback: function ($$v) {
                  _vm.delete_dialog = $$v
                },
                expression: "delete_dialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: {
                  obj: _vm.delete_obj,
                  name: _vm.delete_obj.InvestorName,
                },
                on: {
                  close: function ($event) {
                    _vm.delete_dialog = false
                  },
                  delete: _vm.delete_investor,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }