import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "add-investor-modal", fluid: "", tag: "section" } },
    [
      _c(
        VCard,
        { staticClass: "dxa_modal" },
        [
          _c(VCardTitle, { staticClass: "mb-6" }, [
            _c("h4", { staticClass: "dxa_modal_title h4" }, [
              _vm._v(_vm._s(_vm.page_title())),
            ]),
          ]),
          _c(
            VCardText,
            [
              _c("p", [
                _c("b", { staticStyle: { "margin-right": "4px" } }, [
                  _vm._v(_vm._s(_vm.$t("name")) + ":"),
                ]),
                _vm._v(_vm._s(_vm.event.name) + " "),
              ]),
              _c("p", [
                _c("b", { staticStyle: { "margin-right": "4px" } }, [
                  _vm._v(_vm._s(_vm.$t("date")) + ":"),
                ]),
                _vm._v(_vm._s(_vm.date) + " "),
              ]),
              _c("p", [
                _c("b", { staticStyle: { "margin-right": "4px" } }, [
                  _vm._v(_vm._s(_vm.$t("event_link")) + ":"),
                ]),
                _c("a", { attrs: { href: _vm.event.link, target: "_blank" } }, [
                  _vm._v(_vm._s(_vm.event.link)),
                ]),
              ]),
              _c("p", [
                _c("b", { staticStyle: { "margin-right": "4px" } }, [
                  _vm._v(_vm._s(_vm.$t("details")) + ":"),
                ]),
                _vm._v(_vm._s(_vm.event.details) + " "),
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("go_back")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }