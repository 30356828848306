import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    {
      staticClass: "modal-container",
      class: _vm.gs.isMobile() ? "" : "dxa_modal",
    },
    [
      _c("div", { staticClass: "mb-6 modal-title-container" }, [
        _c("h4", { staticClass: "dxa_modal_title h4" }, [
          _vm._v(_vm._s(_vm.page_title())),
        ]),
      ]),
      _c(
        VCardText,
        [
          _c(
            VForm,
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.checkForm()
                },
              },
            },
            [
              !_vm.edit
                ? _c(VSelect, {
                    attrs: {
                      "data-test": "Vehicle:AddTransactionModal:InvestorEmail",
                      rules: [_vm.required],
                      "return-object": "",
                      items: _vm.investors,
                      "item-text": "InvestorEmail",
                      label: _vm.$tc("user", 1),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.getInvestorCompleteObject()
                      },
                    },
                    model: {
                      value: _vm.user_selected,
                      callback: function ($$v) {
                        _vm.user_selected = $$v
                      },
                      expression: "user_selected",
                    },
                  })
                : _vm._e(),
              _c(VSelect, {
                attrs: {
                  "data-test": "Vehicle:AddTransactionModal:Action",
                  rules: [_vm.required],
                  "item-text": "text",
                  "item-value": "value",
                  items: [
                    {
                      text: _vm.$t("buy_action"),
                      value: _vm.TransactionActionsEnum.BUY,
                    },
                    {
                      text: _vm.$t("sell_action"),
                      value: _vm.TransactionActionsEnum.SELL,
                    },
                  ],
                  label: _vm.$t("action"),
                },
                on: {
                  change: function ($event) {
                    return _vm.checkInvestorStocks()
                  },
                },
                model: {
                  value: _vm.transaction.Action,
                  callback: function ($$v) {
                    _vm.$set(_vm.transaction, "Action", $$v)
                  },
                  expression: "transaction.Action",
                },
              }),
              !_vm.transaction_prop
                ? _c(VSelect, {
                    attrs: {
                      "data-test": "Vehicle:AddTransactionModal:Vehicle",
                      items: _vm.investor_vehicles,
                      "item-text": "Name",
                      "item-value": "Id",
                      label: _vm.$t("vehicle_title_single"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.getStockTypeUserSelected()
                      },
                    },
                    model: {
                      value: _vm.investor_vehicle_select,
                      callback: function ($$v) {
                        _vm.investor_vehicle_select = $$v
                      },
                      expression: "investor_vehicle_select",
                    },
                  })
                : _vm._e(),
              !_vm.transaction_prop
                ? _c(VSelect, {
                    attrs: {
                      "data-test": "Vehicle:AddTransactionModal:Currency",
                      items: _vm.investor_currencys,
                      "item-text": "Name",
                      "item-value": "Id",
                      label: _vm.$t("currency"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.getStockTypeUserSelected()
                      },
                    },
                    model: {
                      value: _vm.investor_currency_select,
                      callback: function ($$v) {
                        _vm.investor_currency_select = $$v
                      },
                      expression: "investor_currency_select",
                    },
                  })
                : _vm._e(),
              _c(VSelect, {
                attrs: {
                  "data-test": "Vehicle:AddTransactionModal:StockType",
                  rules: [_vm.required],
                  items: _vm.stock_type_options,
                  "item-text": "Name",
                  label: _vm.$t("stock_type"),
                },
                on: { change: _vm.handleStockType },
                model: {
                  value: _vm.stock_type,
                  callback: function ($$v) {
                    _vm.stock_type = $$v
                  },
                  expression: "stock_type",
                },
              }),
              !_vm.transaction_prop && !_vm.disabledInputStockValue
                ? _c("div", [
                    _c(
                      "div",
                      [
                        _c("span", { staticClass: "v-label theme--light" }, [
                          _vm._v(" " + _vm._s(_vm.$tc("stock", 1)) + " "),
                        ]),
                        _c(VTextField, {
                          attrs: {
                            "data-test":
                              "Vehicle:AddTransactionModal:StockValue",
                            prefix: _vm.moneyInputPrefix,
                            disabled: _vm.disabledStockValue,
                            rules: [_vm.required],
                            type: "number",
                          },
                          model: {
                            value: _vm.investorStockValue,
                            callback: function ($$v) {
                              _vm.investorStockValue = $$v
                            },
                            expression: "investorStockValue",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(VCheckbox, {
                          staticClass: "field",
                          attrs: { color: "primary", outlined: "", dense: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("edit_stock_value"))
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2595290605
                          ),
                          model: {
                            value: _vm.editStockValue,
                            callback: function ($$v) {
                              _vm.editStockValue = $$v
                            },
                            expression: "editStockValue",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(VTextField, {
                staticClass: "pt-0",
                attrs: {
                  "data-test": "Vehicle:AddTransactionModal:StockQuantity",
                  type: "number",
                  rules: [_vm.required],
                  min: 0,
                  label: _vm.$t("current_position"),
                },
                model: {
                  value: _vm.transaction.StockQuantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.transaction, "StockQuantity", $$v)
                  },
                  expression: "transaction.StockQuantity",
                },
              }),
              _c(VAutocomplete, {
                attrs: {
                  items: _vm.roundOptions,
                  "item-text": "id",
                  "item-value": "id",
                  label: _vm.$t("single_round"),
                  dense: "",
                  id: "userInput",
                  loading: _vm.roundOptionsLoading,
                },
                model: {
                  value: _vm.transaction.InvestmentRoundId,
                  callback: function ($$v) {
                    _vm.$set(_vm.transaction, "InvestmentRoundId", $$v)
                  },
                  expression: "transaction.InvestmentRoundId",
                },
              }),
              _c(
                VMenu,
                {
                  ref: "menuDate",
                  attrs: {
                    "close-on-content-click": false,
                    "return-value": _vm.date,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "290px",
                  },
                  on: {
                    "update:returnValue": function ($event) {
                      _vm.date = $event
                    },
                    "update:return-value": function ($event) {
                      _vm.date = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            VTextField,
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "pt-0",
                                  attrs: {
                                    label: _vm.$t("date"),
                                    "prepend-icon": "mdi-calendar",
                                    rules: [_vm.required],
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.date,
                                    callback: function ($$v) {
                                      _vm.date = $$v
                                    },
                                    expression: "date",
                                  },
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.menuDate,
                    callback: function ($$v) {
                      _vm.menuDate = $$v
                    },
                    expression: "menuDate",
                  },
                },
                [
                  _c(VDatePicker, {
                    staticClass: "mx-auto",
                    on: {
                      change: function ($event) {
                        _vm.$refs.menuDate.save(_vm.date)
                        _vm.menuDate = false
                      },
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCardActions,
                { staticClass: "pl-0 dxa_modal_actions" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnSuccess",
                      attrs: {
                        "data-test":
                          "Vehicle:AddTransactionModal:BtnSaveTransaction",
                        color: "primary",
                        "min-width": "100",
                        loading: _vm.loading,
                        type: "submit",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("save")))]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnError",
                      attrs: { color: "red", "min-width": "100" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close", null)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }