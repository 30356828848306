import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "transactions", fluid: "", tag: "section" } },
    [
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3 mb-5",
          attrs: { color: "#7733FF", icon: "mdi-cash-multiple", inline: "" },
          scopedSlots: _vm._u([
            {
              key: "after-heading",
              fn: function () {
                return [
                  _c("h1", { staticClass: "h4" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$tc("transaction", 2)))]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VBtn,
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.is_admin,
                  expression: "is_admin",
                },
              ],
              attrs: {
                id: "btn-add-new-transaction",
                "data-test": "Vehicle:TransactionsTable:BtnNewTransaction",
                absolute: "",
                fab: "",
                top: "",
                right: "",
                color: "primary",
                rounded: "",
              },
              on: {
                click: function ($event) {
                  return _vm.open_dialog(null)
                },
              },
            },
            [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
            1
          ),
          _c(VSimpleTable, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("name"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("operation"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("vehicle_title_single"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("single_round"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("email"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("stock_quantity"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("stock_price"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$tc("stock", 2))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("date"))),
                ]),
                _c(
                  "th",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.is_admin,
                        expression: "is_admin",
                      },
                    ],
                    staticClass: "text-center",
                  },
                  [_vm._v(_vm._s(_vm.$t("options")))]
                ),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.transactions, function (t, i) {
                return _c("tr", { key: i }, [
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$parent.show_string_value(
                            t.investment.investorName
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          t.action === 0
                            ? _vm.$tc("stock_operation", 1)
                            : _vm.$tc("stock_operation", 2)
                        ) +
                        " "
                    ),
                  ]),
                  t.investment.investmentVehicle
                    ? _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          " " +
                            _vm._s(t.investment.investmentVehicle.name) +
                            " "
                        ),
                      ])
                    : _c("td", { staticClass: "text-center" }, [_vm._v("-")]),
                  t.investmentRoundId
                    ? _c("td", { staticClass: "text-center" }, [
                        _vm._v(" " + _vm._s(t.investmentRoundId) + " "),
                      ])
                    : _c("td", { staticClass: "text-center" }, [_vm._v("-")]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(t.investment.investorEmail)),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(t.stockQuantity)),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatToCurrency(
                            t.investment.currency,
                            t.stockValue
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatToCurrency(
                            t.investment.currency,
                            t.transactionValue
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(" " + _vm._s(_vm.convert_date(t.date)) + " "),
                  ]),
                  _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.is_admin,
                          expression: "is_admin",
                        },
                      ],
                      staticClass: "text-center",
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1 secondary",
                          attrs: {
                            "data-test":
                              "Vehicle:TransactionsTable:BtnEditTransaction" +
                              t.stockQuantity,
                            "min-width": "0",
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.open_dialog(t)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-pencil"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1",
                          attrs: {
                            "data-test":
                              "Vehicle:TransactionsTable:BtnDeleteTransaction" +
                              t.stockQuantity,
                            color: "red",
                            "min-width": "0",
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.open_delete_dialog(t)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-trash-can"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _vm.dialog
        ? _c(
            VDialog,
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c("DashboardFormsAddTransactionModal", {
                attrs: {
                  transaction_prop: _vm.editTransaction,
                  company: _vm.company,
                  investors: _vm.investors,
                },
                on: { close: _vm.reset_dialog },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.delete_dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.delete_dialog,
                callback: function ($$v) {
                  _vm.delete_dialog = $$v
                },
                expression: "delete_dialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: {
                  obj: _vm.delete_obj,
                  name: _vm.$t("this_transaction"),
                },
                on: {
                  close: function ($event) {
                    _vm.delete_dialog = false
                  },
                  delete: _vm.delete_transaction,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }