import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "kpi-table", fluid: "", tag: "section" } },
    [
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3 mb-5",
          attrs: { color: "#7733FF", icon: "mdi-bullseye-arrow", inline: "" },
          scopedSlots: _vm._u([
            {
              key: "after-heading",
              fn: function () {
                return [
                  _c("h1", { staticClass: "h4" }, [
                    _c("b", [_vm._v(_vm._s(_vm.Kpi.Name))]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VBtn,
            {
              attrs: {
                absolute: "",
                fab: "",
                top: "",
                right: "",
                color: "secondary",
                rounded: "",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("reset")
                },
              },
            },
            [
              _c(VIcon, { attrs: { "x-large": "" } }, [
                _vm._v(" mdi-arrow-left-bold "),
              ]),
            ],
            1
          ),
          _c(VSimpleTable, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("date"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("current_value"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("edit"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("remove"))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.Kpi.KpiValueHistory, function (history, i) {
                return _c("tr", { key: i }, [
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " + _vm._s(_vm.get_date_formated(history.Date)) + " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(" " + _vm._s(history.Value) + " "),
                  ]),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1",
                          attrs: {
                            color: "secondary",
                            "min-width": "0",
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.open_edit_dialog(history)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-pencil"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1",
                          attrs: { color: "red", "min-width": "0", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.open_delete_dialog(history)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-trash-can"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _vm.dialogEdit
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialogEdit,
                callback: function ($$v) {
                  _vm.dialogEdit = $$v
                },
                expression: "dialogEdit",
              },
            },
            [
              _c("EditKpiHistoryModal", {
                attrs: { History: _vm.editKpi },
                on: { close: _vm.reset_dialogEdit },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.delete_dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.delete_dialog,
                callback: function ($$v) {
                  _vm.delete_dialog = $$v
                },
                expression: "delete_dialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: { obj: _vm.delete_obj, name: _vm.$t("this_kpi") },
                on: {
                  close: function ($event) {
                    _vm.delete_dialog = false
                  },
                  delete: _vm.delete_kpi,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }