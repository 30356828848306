import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { class: _vm.gs.isMobile() ? "" : "dxa_modal" },
    [
      _c(VCardTitle, { staticClass: "mb-6" }, [
        _c("h4", { staticClass: "dxa_modal_title h4" }, [
          _vm._v(
            " " +
              _vm._s(_vm.edit ? _vm.$t("edit_kpi") : _vm.$t("add_kpi")) +
              " "
          ),
        ]),
      ]),
      _c(
        VCardText,
        [
          _c(
            VForm,
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.submit()
                },
              },
            },
            [
              _c(VTextField, {
                staticClass: "pt-0",
                attrs: {
                  label: _vm.$t("name_pt"),
                  "data-test": "Company:Kpi:Add:Name",
                },
                model: {
                  value: _vm.kpi.Name,
                  callback: function ($$v) {
                    _vm.$set(_vm.kpi, "Name", $$v)
                  },
                  expression: "kpi.Name",
                },
              }),
              _c(VTextField, {
                staticClass: "pt-0",
                attrs: {
                  label: _vm.$t("name_en"),
                  "data-test": "Company:Kpi:Add:NameEn",
                },
                model: {
                  value: _vm.nameEn,
                  callback: function ($$v) {
                    _vm.nameEn = $$v
                  },
                  expression: "nameEn",
                },
              }),
              _c(VTextField, {
                staticClass: "pt-0",
                attrs: {
                  label: _vm.$t("description"),
                  "data-test": "Company:Kpi:Add:Description",
                },
                model: {
                  value: _vm.kpi.Description,
                  callback: function ($$v) {
                    _vm.$set(_vm.kpi, "Description", $$v)
                  },
                  expression: "kpi.Description",
                },
              }),
              _c(VTextField, {
                staticClass: "pt-0",
                attrs: {
                  "data-test": "Company:Kpi:Add:Unit",
                  label: _vm.$t("unit"),
                },
                model: {
                  value: _vm.kpi.Unit,
                  callback: function ($$v) {
                    _vm.$set(_vm.kpi, "Unit", $$v)
                  },
                  expression: "kpi.Unit",
                },
              }),
              _c(VSelect, {
                staticClass: "pt-0",
                attrs: {
                  items: _vm.Indexes,
                  label: _vm.$t("index"),
                  "data-test": "Company:Kpi:Add:Index",
                },
                model: {
                  value: _vm.kpi.Index,
                  callback: function ($$v) {
                    _vm.$set(_vm.kpi, "Index", $$v)
                  },
                  expression: "kpi.Index",
                },
              }),
              _c(VSelect, {
                attrs: {
                  label: _vm.$t("select_graph_type"),
                  dense: "",
                  items: _vm.selectedChartType,
                  "item-text": _vm.translateDefaultGraph,
                  "item-value": "value",
                },
                model: {
                  value: _vm.kpi.DefaultGraph,
                  callback: function ($$v) {
                    _vm.$set(_vm.kpi, "DefaultGraph", $$v)
                  },
                  expression: "kpi.DefaultGraph",
                },
              }),
              _c(VTextField, {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["##/####"],
                    expression: "['##/####']",
                  },
                ],
                staticClass: "pt-0",
                attrs: {
                  label: _vm.$t("x_axis_start"),
                  rules: [_vm.vs.validDate(_vm.x_start, false)],
                },
                on: {
                  blur: function ($event) {
                    _vm.kpi.XAxisStart = _vm.gs.parse_to_datetime_string(
                      _vm.x_start,
                      "MM/YYYY"
                    )
                  },
                },
                model: {
                  value: _vm.x_start,
                  callback: function ($$v) {
                    _vm.x_start = $$v
                  },
                  expression: "x_start",
                },
              }),
              _c(VTextField, {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["##/####"],
                    expression: "['##/####']",
                  },
                ],
                staticClass: "pt-0",
                attrs: {
                  label: _vm.$t("x_axis_end"),
                  rules: [_vm.vs.validDate(_vm.x_end, false)],
                },
                on: {
                  blur: function ($event) {
                    _vm.kpi.XAxisEnd = _vm.gs.parse_to_datetime_string(
                      _vm.x_end,
                      "MM/YYYY"
                    )
                  },
                },
                model: {
                  value: _vm.x_end,
                  callback: function ($$v) {
                    _vm.x_end = $$v
                  },
                  expression: "x_end",
                },
              }),
              _c(VTextField, {
                staticClass: "pt-0",
                attrs: { label: _vm.$t("y_axis_start"), type: "number" },
                on: {
                  change: function ($event) {
                    _vm.kpi.YAxisStart =
                      _vm.kpi.YAxisStart === "" ? null : _vm.kpi.YAxisStart
                  },
                },
                model: {
                  value: _vm.kpi.YAxisStart,
                  callback: function ($$v) {
                    _vm.$set(_vm.kpi, "YAxisStart", _vm._n($$v))
                  },
                  expression: "kpi.YAxisStart",
                },
              }),
              _c(VTextField, {
                staticClass: "pt-0",
                attrs: { label: _vm.$t("y_axis_end"), type: "number" },
                on: {
                  change: function ($event) {
                    _vm.kpi.YAxisEnd =
                      _vm.kpi.YAxisEnd === "" ? null : _vm.kpi.YAxisEnd
                  },
                },
                model: {
                  value: _vm.kpi.YAxisEnd,
                  callback: function ($$v) {
                    _vm.$set(_vm.kpi, "YAxisEnd", _vm._n($$v))
                  },
                  expression: "kpi.YAxisEnd",
                },
              }),
              _c(
                VCardActions,
                { staticClass: "pl-0 dxa_modal_actions" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnSuccess",
                      attrs: {
                        color: "primary",
                        "min-width": "100",
                        loading: _vm.loading,
                        type: "submit",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("save")))]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnError",
                      attrs: { color: "red", "min-width": "100" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close", null)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.error
            ? _c(VAlert, { staticClass: "ma-2", attrs: { type: "error" } }, [
                _vm._v(" " + _vm._s(_vm.$t(_vm.error)) + " "),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }