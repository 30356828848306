import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "add-file-modal", fluid: "", tag: "section" } },
    [
      _c(
        VCard,
        { staticClass: "dxa_modal" },
        [
          _c(VCardTitle, [
            _c("h4", { staticClass: "dxa_modal_title h4" }, [
              _vm._v(_vm._s(_vm.$t("new_file"))),
            ]),
          ]),
          _c(
            VCardText,
            [
              _c(
                VForm,
                {
                  ref: "form",
                  staticClass: "mt-5",
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.submit()
                    },
                  },
                },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.index_options,
                      label: _vm.$t("index"),
                      outlined: "",
                    },
                    model: {
                      value: _vm.selected_index,
                      callback: function ($$v) {
                        _vm.selected_index = $$v
                      },
                      expression: "selected_index",
                    },
                  }),
                  !_vm.edit
                    ? _c("input", {
                        ref: "file",
                        attrs: {
                          type: "file",
                          id: "file",
                          prefix: "",
                          label: "File input",
                        },
                        on: { change: _vm.files_changed },
                      })
                    : _vm._e(),
                  _c(
                    VCardActions,
                    { staticClass: "pl-0 dxa_modal_actions" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa_modal_btnSuccess",
                          attrs: {
                            color: "primary",
                            "min-width": "100",
                            loading: _vm.loading,
                            type: "submit",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("save")))]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa_modal_btnError",
                          attrs: { color: "red", "min-width": "100" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("cancel")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }