import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "add-stock-modal", fluid: "", tag: "section" } },
    [
      _c(
        VCard,
        { staticClass: "dxa_modal" },
        [
          _c(VCardTitle, { staticClass: "mb-6" }, [
            _c("h4", { staticClass: "dxa_modal_title h4" }, [
              _vm._v(" " + _vm._s(_vm.$t("edit_stocks")) + " "),
            ]),
          ]),
          _c(
            VCardText,
            [
              _c(
                VForm,
                {
                  ref: "form",
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.submit()
                    },
                  },
                },
                [
                  _c(
                    VMenu,
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "290px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        label: _vm.$t("value_change_date"),
                                        readonly: "",
                                        disabled: _vm.HistoryDate != null,
                                      },
                                      model: {
                                        value: _vm.date,
                                        callback: function ($$v) {
                                          _vm.date = $$v
                                        },
                                        expression: "date",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu2,
                        callback: function ($$v) {
                          _vm.menu2 = $$v
                        },
                        expression: "menu2",
                      },
                    },
                    [
                      _c(VDatePicker, {
                        on: {
                          change: _vm.date_changed,
                          input: function ($event) {
                            _vm.menu2 = false
                          },
                        },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.selected_date_stocks, function (stock, i) {
                    return _c(VTextField, {
                      key: i,
                      staticClass: "pt-0",
                      attrs: {
                        type: "number",
                        min: "0",
                        label:
                          _vm.$t("single_stock") + " " + stock.StockType.Name,
                      },
                      model: {
                        value: stock.Value,
                        callback: function ($$v) {
                          _vm.$set(stock, "Value", $$v)
                        },
                        expression: "stock.Value",
                      },
                    })
                  }),
                  _vm.selected_date_stocks.length == 0
                    ? _c("div", { staticClass: "text-center my-4" }, [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("no_stocks")) + " "),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    VCardActions,
                    { staticClass: "pl-0 dxa_modal_actions" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa_modal_btnSuccess",
                          attrs: {
                            color: "primary",
                            "min-width": "100",
                            loading: _vm.loading,
                            type: "submit",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("save")))]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa_modal_btnError",
                          attrs: { color: "red", "min-width": "100" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close", null)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm.alert_msg != null
                ? _c(
                    VAlert,
                    { staticClass: "ma-2", attrs: { type: "error" } },
                    [_vm._v(" " + _vm._s(_vm.$t(_vm.alert_msg)) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }